import { DatabaseType } from "../components/query/dtoInterfaces"

const Databases = {
    PostgreSQL: {
        type: DatabaseType.PostgresSql,
        imagePath: "postgresql.svg",
    },
    MySQL: {
        type: DatabaseType.MySql,
        imagePath: "mysql.svg"
    },
    SQLite: {
        type: DatabaseType.Sqlite,
        imagePath: "sqlite.svg"
    },
}

const getDatabaseIcon = (type: number) => {
    switch (type){
        case Databases.PostgreSQL.type:
            return Databases.PostgreSQL.imagePath;
        case Databases.MySQL.type:
            return Databases.MySQL.imagePath;
        case Databases.SQLite.type:
            return Databases.SQLite.imagePath;
        default: return undefined;
    }
}

export default getDatabaseIcon;