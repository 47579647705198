export interface Database {
    id: number;
    name: string;
    databaseType: DatabaseType
}

export interface Version {
    id : number;
    dateCreate : Date;
    authorFullName : string;
    sqlQuery : string;
    parameters : Array<Parameter>
    query: Query,
    statistic: Statistic
};

export interface Query {
    id : number;
    name : string;
    database: Database;
    queryVersions : Array<Version>;
    lastChange: string;
    lastExecution: string;
    lastExport: string;
}

export interface Parameter {
    name : string;
    symbol : string;
    compareOperator : string;
    value : any;
    dataType : number;
    saved: boolean;
};

export interface Statistic {
    usedFields : Array<UsedDatabaseObjectField>;
    usedDatabaseObjects : Array<UsedDatabaseObject>;
    infoColumns : Array<ColumnInfo>;
};

export interface UsedDatabaseObjectField {
    databaseName : string;
    schemaName : string;
    objectName : string;
    metadataFieldName : string;
    metadataFieldFieldType : string;
};

export interface UsedDatabaseObject {
    name : string;
    schemaName : string;
    objectName : string;
};

export interface ColumnInfo {
    name : string;
    type : string;
    columnAlias : string;
    objectName : string;
    expression : string;
};

export enum DatabaseType {
    PostgresSql,
    MySql,
    Sqlite
}