import { Button, makeStyles, TextField } from "@material-ui/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

interface Header {
  queryName?: string, 
  setQueryName?: React.Dispatch<React.SetStateAction<string>>, 
  handleClose: (save: boolean) => () => Promise<void>, 
  canSave: boolean,
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  }
}));

const Header = ({queryName, setQueryName, handleClose, canSave}: Header) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div>
        {queryName != undefined &&
          <TextField
            key="createQueryInput"
            value={queryName}
            label="Query name"
            onChange={(e) => setQueryName && setQueryName(e.target.value)}
          />
        }
      </div>
      <div>
        <Button
          onClick={handleClose(true)}
          color="primary"
          disabled={canSave}  
        >
          Save
        </Button>
        <Button onClick={handleClose(false)} color="primary">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default observer(Header);