import { Database, DatabaseType, Parameter, Query } from "../components/query/dtoInterfaces";
import { config } from "../configuration";

const customFetch = async (
  relativeUrl: string,
  body: any,
  method: string,
  isFile = false
) => {
  const response = await fetch(config.baseUrl + relativeUrl, {
    method,
    headers: getHeaders(isFile),
    body: body == null ? null : isFile ? body : JSON.stringify(body),
  });
  
  if (response.status === 401) {
    // @ts-ignore
    window.accountService.logout();
    document.location.reload();
  }

  return response;
};

const getHeaders = (isFile: boolean) => {
  const headers: Headers = {};
  // @ts-ignore
  const token = localStorage.getItem(window.accountService.tokenName);
  // @ts-ignore
  const databaseType = window.queryService?.currentDatabase?.databaseType as DatabaseType;
  if (token != null) headers["Authorization"] = `Bearer ${token}`;
  if (Number.isInteger(databaseType))
    headers["DatabaseType"] = databaseType.toString(); 

  if (!isFile) headers["Content-Type"] = "application/json";

  return headers;
};

const fetchPost = async (relativeUrl: string, body?: object, isFile: boolean = false) => await customFetch(relativeUrl, body, "POST", isFile);

const fetchPut = async (relativeUrl: string, body: object) => await customFetch(relativeUrl, body, "PUT");

const fetchGet = async (relativeUrl: string) => await customFetch(relativeUrl, null, "GET");

const sendGetRequest = async (path: string) => {
  return executeRequest(async () => await fetchGet(path));
};

const sendPutRequest = async (path: string, payload?: any) => {
  return executeRequest(async () => await fetchPut(path, payload));
};

const sendPostRequest = async <T extends Data>(path: string, payload?: any): Promise<T | FailureData> => {
  return executeRequest(async () => await fetchPost(path, payload));
};

const executeRequest = async (request: () => Promise<Response>) => {
  try {
    const response = await request();
    return response.json();
  } catch {
    return null;
  }
};

const signin = async (login: string, password: string) => {
  return await sendPostRequest<Sign>("/account/signin", { login, password });
};

const signup = async (login: string, password: string) => {
  return await sendPostRequest<Sign>("/account/signup", { login, password });
};


const signinSso = async (login: string, token: string) => {
  const result = await sendPostRequest<Sign>("/account/signin-sso", { login, token });
  console.log(result);
  return result;
}


const setSqlToAQB = async (currentVersionIdSelected: number, token: string) => {
  return await sendPutRequest(
    `/queryVersions/setQuery/${currentVersionIdSelected}`,
    { token }
  );
};

const createQuery = async (queryName: string, token: string, databaseId: number) => {
  return await sendPostRequest<any>("/query", { queryName, token, databaseId });
};

const createNewQueryVersion = async (queryVersionId: number, queryName: string, token: string) => {
  return await sendPutRequest(`/queryVersions/${queryVersionId}`, { queryName, token });
};

const editQuery = async (queryId: number, queryName: string) => {
  return await sendPutRequest(`/query/${queryId}`, { queryName });
};

const getQueries = async (databaseId: number) => {
  return (await sendGetRequest(`/query/allbyuser?databaseId=${databaseId}`)) as Array<Query>;
};

const executeQuery = async (queryVersionId: number, token: string, start: number, end: number, parameters: string, isSubQuery: boolean, sortName?: string, order?: string | undefined | null) => {
  let total = 0;
  try {
    const response = await fetchGet(`/queryVersions/query?queryVersionId=${queryVersionId}&isSubQuery=${isSubQuery}&token=${token}&_start=${start}&_end=${end}&parameters=${parameters}${sortName ? `&_sort=${sortName}` : ""}${order ? `&_order=${order}` : ""}`);
    const totalHeaderValue = response.headers.get("x-total-count");
    
    if (totalHeaderValue !== undefined && totalHeaderValue !== null)
      total = parseInt(totalHeaderValue);
    else {
      return await response.json();
    }

    return { data: await response.json() as Array<any>, total };
  } catch {
    return null;
  }
};

const exportQueryResult = async (queryVersionId: number, token: string, parameters: string) => {
  return await fetchGet(`/queryVersions/export?queryVersionId=${queryVersionId}&token=${token}&parameters=${parameters}`);
};

const getParameters = async (id: number) => {
  return (await sendGetRequest(`/queryVersions/getParameters/${id}`)) as Array<any>;
};

const getTypeColumns = async (token: string, isSubQuery: boolean) => {
  return (await sendGetRequest(`/queryVersions/getTypeColumns?token=${token}&isSubQuery=${isSubQuery}`)) as Array<any>;
};

const getStatistics = async (token: string) => {
  return (await sendGetRequest(`/queryVersions/getStatistic?token=${token}`)) as Array<any>;
};

const getAllDatabases = async () => {
  return (await sendGetRequest(`/database/all`)) as Array<Database>;
};

const getQueryById = async (id: number) => {
  return (await sendGetRequest(`/query/${id}`)) as Query;
};

const getParametersByQuery = async (versionId: number) => {
  return await sendGetRequest(`/parameter?queryVersionId=${versionId}`);
};

const saveParameters = async (parameters: Parameter[], versionId: number) => {
  return await sendPostRequest<any>("/parameter", { parameters, versionId });
};

export default {
  signin,
  signup,
  signinSso,
  createQuery,
  getQueries,
  setSqlToAQB,
  executeQuery,
  getParameters,
  getTypeColumns,
  exportQueryResult,
  createNewQueryVersion,
  getStatistics,
  getAllDatabases,
  editQuery,
  getQueryById,
  saveParameters,
  getParametersByQuery
};

interface Headers {
  [key: string]: string;
}

type Data = {
  succeeded: boolean;
};

export type FailureData = Data & {
  message: string;
};

export type Sign = Data & {
  token: string;
};
