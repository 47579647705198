import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Routes } from "../../configuration";
import AccountService from "../../services/accountService/accountService";
import ServicesContext, { Context } from "../../services/ServicesContext";

const SsoAuth = () => {

    const history = useHistory();
    const params = new URLSearchParams(useLocation().search);
    const accountService = useContext<Context>(ServicesContext).accountService;
    const [text, setText] = useState<string>("");
    const dot = '.';

    useEffect(() => {
        setInterval(() => {
            if (text === '...')
                setText(dot);
            else
                setText(prev => prev + dot);
        }, 1000)
    }, [text, setText])

    useEffect(() => {
        const token = params.get("token");
        const login = params.get("login");
        const redirectUrl = params.get("redirectUrl");

        if (!token || !login) {
            if (redirectUrl)
                document.location.href = redirectUrl;
            else
                history.push(Routes.signIn);
            
            return;
        } 

        (async () => {
            await accountService.signInByToken(login, token);
            await waitToken(accountService);
            if (redirectUrl)
                document.location.href = redirectUrl;
            else
                history.push(Routes.list);
        })();
    }, []);

    return(
        <div>
            Please wait for redirect{text}
        </div>
    )
}

const waitToken = async (accountService: AccountService): Promise<void> => {
    if (accountService.currentToken) {
        console.log("Got token");
        return new Promise((res, rej) => {});
    }
    else {
        console.log("New try");
        return new Promise ((res, rej) => setTimeout(async () => res(await waitToken(accountService)), 1000));
    }
}

export default SsoAuth;