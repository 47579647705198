import { createTheme, ThemeProvider } from "@material-ui/core";
import React, { useEffect } from "react";
import Layout from "./components/layouts/Layout";
import api from "./dataProvider/api";
import Router from "./Router";
import AccountService from "./services/accountService/accountService";
import QueryService, { databaseIdName } from "./services/queryService/queryService";
import { ServicesContextProvider } from "./services/ServicesContext";
import "./App.css";
import customTheme from "./theme";
import CssBaseline from '@material-ui/core/CssBaseline';

const services = { 
  queryService: new QueryService(),
  accountService: new AccountService()
};
// @ts-ignore
window.accountService = services.accountService
// @ts-ignore
window.queryService = services.queryService;

export default () => {
  useEffect(() => {
    (async () => {
      await services.queryService.loadAllDatabases();
      const databaseIdFromStorage = localStorage.getItem(databaseIdName);
      if (databaseIdFromStorage) {
        try {
          const databaseId = Number.parseInt(databaseIdFromStorage);
          services.queryService.trySetCurrentDatabase(databaseId);
        }
        catch {
         console.error("database id was wrong format in localstorage"); 
        }
      }
    })();
  }, []);

  return (
    <ServicesContextProvider value={{
        queryService: services.queryService,
        accountService: services.accountService
      }}
    >
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <Layout>
          <Router />
        </Layout>
      </ThemeProvider>
    </ServicesContextProvider>
  );
};
