import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { routeros } from "react-syntax-highlighter/dist/esm/styles/hljs";
//paraisoLight

const useStyles = makeStyles({
  codeBlock: {
    "& > pre": {
      margin: 0,
      height: "90%",
      width: "100%"
    },
    marginLeft: 8,
    minWidth: 600,
    height: "100%",
  },
  syntax: {
    width: "100%",
    padding: "20px!important",
    borderRadius: "5px",
    height: "100%",
  }
});

const CodeBlock: FC<{query: string}> = ({query}) => {
  const classes = useStyles();
  
  return (
    <ReactMarkdown
      className={classes.codeBlock}
      children={query} 
      components={{
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '');
          //@ts-ignore
          return !inline && match ? (<SyntaxHighlighter
              className={classes.syntax}
              children={String(children).replace(/\n$/, '')}
              style={routeros}
              language={match[1]}
              PreTag="div"
              wrapLines={true}
              lineProps={{style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
              {...props}
            />
          ) : (
            <code className={classes.codeBlock} {...props}>
              {children}
            </code>
          )
        }
      }}  
    />
  )
};

export default CodeBlock;