const getCorrectDate = (date: string) => {
    let prettifiedDate = "";
    if (date){
        let dateFromString = new Date(date);
        prettifiedDate = `${addZero(dateFromString.getDate().toString())}.${addZero((dateFromString.getMonth() + 1).toString())}.${dateFromString.getFullYear()}: ${dateFromString.toLocaleTimeString()}`;
    }
    return prettifiedDate;
}

const addZero = (dateString: string) => {
    if (dateString.length < 2)
        return "0" + dateString;
    else 
        return dateString;
}


export default getCorrectDate;