import { Button } from "@material-ui/core";
import React, { FC, useState } from "react";
import { CustomDialog } from ".";
import { makeStyles } from "@material-ui/core";


const ShowDialog: FC<ShowDialogProps> = ({content, dialogTitle, openButtonName, openButtonIcon, hideOverflow = false}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (save: boolean) => async () => {
    setOpen(false);
  };

  const useStyles = makeStyles({
    dialog: {
      minWidth: "90%",
      minHeight: "90%",
    },
    dialogContent: {
      overflowY: "hidden",
      paddingBottom: "30px",
    }
  });

  const dialogClasses = useStyles();

  const openButton = (handleClickOpen: () => void) => (
    <Button
      onClick={handleClickOpen}
      variant="contained" 
      startIcon={openButtonIcon} 
    >
      {openButtonName}
    </Button>
  ); 

  return (
    <CustomDialog
      openButton={openButton}
      open={open}
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
      textButton={"Create a new query"}
      classes={{
        root: dialogClasses.dialog,
        dialogContent: hideOverflow ? dialogClasses.dialogContent : "",
      }}
      actions={
        <div>
          <Button onClick={handleClose(false)} color="primary">
            Close
          </Button>
        </div>
      }
      dialogContent={content}
      dialogTitle={dialogTitle}
    />
  );
};

interface ShowDialogProps {
  content: JSX.Element,
  dialogTitle: string,
  openButtonName: string,
  openButtonIcon: JSX.Element,
  hideOverflow?: boolean,
}

export default ShowDialog;