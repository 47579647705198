import React from "react";
import AccountService from "./accountService/accountService";
import QueryService from "./queryService/queryService";

const ServicesContext = React.createContext<Context>(
  {
    queryService: new QueryService(),
    accountService: new AccountService()
  }
);
export const ServicesContextProvider = ServicesContext.Provider;
export default ServicesContext;

export interface Context {
  queryService: QueryService,
  accountService: AccountService
}