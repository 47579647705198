import { Button, Card, Snackbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit, ImportExport, ArrowBack } from "@material-ui/icons";
import download from "downloadjs";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { format } from "sql-formatter";
import { GetTokenForQueryBuilder } from "../../../aqb/helper";
import api from "../../../dataProvider/api";
import { getFileName } from "../../../helpers/fileHelper";
import { getFormatedDateFromString } from "../../../helpers/timeHelper";
import ServicesContext, { Context } from "../../../services/ServicesContext";
import CodeBlock from "../../common/CodeBlock";
import EditableTextField from "../../common/EditableTextField";
import ExecuteQuery from "../ExcuteQuery";
import ParametersDisplay from "../Parameters/ParametersDisplay";
import QueryStatistics from "../QueryStatistics";
import EditQuery from "../EditQuery";
import QueryString from "../QueryString";
import { databaseIdName } from "../../../services/queryService/queryService";
import { Parameter } from "../dtoInterfaces";

const useStyles = makeStyles((theme) => ({
  infoDates: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  queryInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    "& > div": {
      marginTop: "10px",
    },
    "& > div:nth-child(2)": {
      marginTop: "0",
    },
    "& h1": {
      margin: 0
    },
  },
  queryButtons: {
    display: "flex",
    "& > :first-child": {
      marginRight: 20
    },
    marginTop: 15,
    marginBottom: 25,
  },
  editName: {
    display: "flex",
    alignItems: "center",
    "& > span": {
      width: 16,
      height: 16
    }
  },
  card: {
    alignItems: "top",
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: 600,
    paddingBottom: 40,
    paddingRight: 20,
    paddingLeft: 12,
  },
  sqlText: {
    width: "100%",
    height: "380px"
  },
  header: {
    background: "#009BC3",
    height: 65,
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  headerButtons: {
    display: "flex",
    "& button": {
      margin: "10px",
      background: "#ffffff",
      color: `${theme.palette.primary.main}`
    },
  },
  backButton: {
    margin: "10px",
    color: "#009BC3"
  },
  queryName: {
    display: "flex",
    alignItems: "center",
    marginLeft: "8px"
  },
  contentFlex: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px"
  }
}));

const QueryInfo: React.FC = () => {
  const classes = useStyles();
  const [canExecuteQuery, setCanExecuteQuery] = useState<boolean>(false);
  const params = useParams<{queryId: string}>();
  const queryId = parseInt(params.queryId as string);

  const { queryService } = useContext(ServicesContext);

  const parameters = queryService.currentQueryVersion?.parameters;
  const currentQuery = queryService.currentQuery;
  const currentQueryVersion = queryService.currentQueryVersion;
  const paramValues = parameters?.map((p) => p.value);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  useEffect(() => {
    const allParametersFilled = parameters?.every((p) => p.value !== undefined && p.value !== null);
    if (currentQueryVersion !== undefined && parameters) {
      if (parameters?.length === 0 || allParametersFilled) {
        setCanExecuteQuery(true);
      }
      else setCanExecuteQuery(false);
    }
  }, [currentQuery, currentQueryVersion, parameters, paramValues]);

  useEffect(() => {
    (async () => {
      if (queryService.currentQueryVersion !== undefined)
        await api.setSqlToAQB(queryService.currentQueryVersion.id, GetTokenForQueryBuilder(queryService.currentDatabase!.name))
    })();
  }, [queryService.currentQueryVersion]);

  useEffect(() => {
    (async () => {
        try {
          if (!queryService.currentDatabase){
            let databaseId = parseInt(localStorage.getItem(databaseIdName) as string);
            await queryService.loadAllDatabases();
            queryService.trySetCurrentDatabase(databaseId);
          }
          await updateQueryVersionData();
        }
        catch (e) {
         console.error("Unable to set query", e); 
        }
    })();
  }, []);

  const updateQueryVersionData = async () => {
    let queryResponse = await api.getQueryById(queryId);
    queryService.setNewQuery(queryResponse);

    let parameters = (await api.getParametersByQuery(queryService.currentQueryVersion?.id as number)) as Parameter[];
    queryService.setCurrentQueryParameters(parameters);
  }

  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway')
      return;

    setOpen(false);
  };

  const exportQueryResult = async () => {
    if (!currentQueryVersion)
      return;
    
    let errorMessage = "Error while exporting";

    const result = await api.exportQueryResult(currentQueryVersion.id, GetTokenForQueryBuilder(queryService.currentDatabase!.name), JSON.stringify(parameters));
    const contentType = result.headers.get("content-type");
    const isResponseBlobType = contentType && contentType.indexOf("application/octet-stream") !== -1;
    await queryService.updateCurrentQuery();

    if (result.ok && isResponseBlobType) {
      download(await result.blob(), getFileName(result), "text/xlsx");
      return;
    }
    else if (result.ok && !isResponseBlobType)
    {
      const error = await result.json();
      errorMessage = error.message;
    }

    setErrorMessage(errorMessage);
    setOpen(true);
  };

  const updateQueryName = async (newName: string) => {
    const result = await api.editQuery(currentQuery!.id, newName);
    if (result.error)
      return result.error;
    else
      currentQuery!.name = newName;
    return undefined;
  }

  const changeParametersVersion = (value: number) => {
    queryService.changeCurrentQueryParameters(value);
  }
  
  const Header = () => {
    if (!currentQuery)
      return <div className={classes.header}></div>;
       
    return (
      <div className={classes.header}>
        <div>
          <Button
            startIcon={<ArrowBack />}
            className={classes.backButton}
            variant="contained" color="secondary"
            onClick={() => history.goBack()}
          >
            GO BACK
          </Button>
        </div>
        <div className={classes.headerButtons}>
          <EditQuery
            updateCurrentQueryInfo={updateQueryVersionData}
          />
          <QueryStatistics />
          <QueryString />
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <Card className={classes.card}>
        <div className={classes.queryInfo}>
          <div className={classes.contentFlex}>
            <div>
              <div className={classes.queryName}>
                <Typography variant="h6">Query</Typography>
                <EditableTextField initialValue={currentQuery?.name || ""} handleUpdate={updateQueryName}/>
              </div>
              <div>
                <Typography style={{marginLeft: "8px"}} variant="h6">Parameter values:</Typography>
                <ParametersDisplay
                  parameterVersionsCount={queryService.allQueryVersionParameters.length}
                  changeParametersVersion={(value: number) => changeParametersVersion(value)}
                />
              </div>
            </div>
            <div>
              <div className={classes.infoDates}>
                <div className={classes.queryButtons}>
                  <ExecuteQuery
                      canExecuteQuery={canExecuteQuery}
                      currentQueryVersion={currentQueryVersion}
                    />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={exportQueryResult}
                    disabled={!canExecuteQuery}
                    startIcon={<ImportExport />}
                  >
                    Export
                  </Button>
                </div>
                <div>
                  <Typography>Last change: {currentQuery?.lastChange ? getFormatedDateFromString(currentQuery.lastChange) : ""}</Typography>
                  <Typography>Last run: {currentQuery?.lastExecution ? getFormatedDateFromString(currentQuery.lastExecution) : ""}</Typography>
                  <Typography>Last exported: {currentQuery?.lastExport ? getFormatedDateFromString(currentQuery.lastExport) : ""}</Typography>
                </div>
              </div>
            </div>
          </div>
          <Snackbar 
            open={open} 
            autoHideDuration={2000} 
            onClose={handleClose}
            message={errorMessage}
          />
        </div>
        <div className={classes.sqlText}>
            <CodeBlock query={`~~~sql\n${format(currentQueryVersion?.sqlQuery || "")}\n~~~`}/>
        </div>
    </Card>
    </div>
  );
};

export default observer(QueryInfo);
