import { createTheme  } from '@material-ui/core/styles';

import ClarikaOfficeGeo2 from '../fonts/ClarikaOfficeGeo-Rg.woff2';
import ClarikaOfficeGeo from '../fonts/ClarikaOfficeGeo-Rg.woff';
import ClarikaOfficeGeoSVG from '../fonts/ClarikaOfficeGeo-Rg.svg';

type FontFaceFontDisplayProperty = "auto" | "block" | "fallback" | "optional" | "swap";
let swapType: FontFaceFontDisplayProperty = "swap";

const regular = {
  fontFamily: 'Clarika Office Geometric',
  fontStyle: 'normal',
  fontWeight: 400,
  fontDisplay: swapType,
  src: `
    url(${ClarikaOfficeGeo2}) format('woff2'),
    url(${ClarikaOfficeGeo}) format('woff'),
    url(${ClarikaOfficeGeoSVG}) format('svg');
    `,
};

const customTheme = createTheme({
    typography: {
        fontFamily: 'Clarika Office Geometric'
    },
    palette: {
        primary: {
            main : '#009BC3',
            dark : '#009BC3'
        },
        secondary: {
            main: '#F7F7F8',
        },
        
    },

    overrides: {
        MuiCssBaseline: {
            '@global': {
              '@font-face': [regular],
            },
        },
        MuiTypography: {
            body2: {
                fontSize: '14px',
            }
        },
        
        MuiAppBar:{
            root: {
                boxShadow:'0px 4px 8px rgba(0, 0, 0, 0.08);',
            },
            colorSecondary : {
                color: '#009BC3',
                backgroundColor: '#fff'
            },
        },

        MuiToolbar: {
            dense : {
                minHeight: '92px'
            }
        },

        MuiMenuItem: {
            root: {
                fontSize: '16px',
                paddingLeft: '24px',
            }
        },

        MuiPopover: {
            paper: {
                borderRadius: 8,
                boxShadow: '0px 2px 6px rgba(34, 43, 85, 0.06), 0px 10px 16px rgba(52, 53, 70, 0.06)'
            }
        },

        MuiList :{
            padding: {
                padding: '0 !important'
            }
            
        },
        MuiListItem: {
            button: {
                '&:hover':{
                    backgroundColor: 'rgba(0, 155, 195, .12)',
                }
            }
        },

        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
                border: '.5px solid #F7F7F8'

            }
        },
        
        MuiListItemIcon: {
            root: {
                color: '#009BC3'
            }
        },

        MuiButton: {
            textPrimary: {
                color: '#009BC3',
                background :'rgba(0, 155, 195, 0.12)',
                borderRadius: '8px',
                padding: '8px 24px',
                margin: '0 8px'
            },
        },

        MuiTableCell: {
            sizeSmall: {
                fontSize: '14px',
                padding: '9px 16px 11px 16px',
                lineHeight: '100%',
                border: '.5px solid #F7F7F8',
                overflow: "hidden",
                maxWidth: "15em",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
            },
        },
        


        // RaDatagrid : {
        //     headerCell: {
        //         fontSize: '14px',
        //         color: '#646469',
        //         backgroundColor: '#F7F7F8',
        //         maxWidth: 120,
        //         fontWeight: 'bold',
        //         whiteSpace: "normal",
        //         borderTop: 0
        //     },

        //     expandIcon: {
        //         '& .MuiSvgIcon-root': {
                    
        //                 // width: '12px',
                    
        //         }
        //     }
        // },
        MuiFormLabel: {
            root:{
                color: '#646469',
                   
                '&.Mui-focused': {
                    color: '#646469 !important',
                }
            },
        },
        MuiInput: {
            underline: {
                '&:hover:before': {
                    borderBottom: '2px solid #009BC3 !important'
                },
                '&:before' : {
                    borderBottom: '2px solid #DCDCE1'
                },
                '&:after' : {
                    borderBottom: '2px solid #009BC3'
                },
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0)',

                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                },

                '&.Mui-focused' : {
                    background: 'none'
                }
            },
            underline: {
                '&:hover:before': {
                    borderBottom: '2px solid #009BC3'
                },
                '&:before' : {
                    borderBottom: '2px solid #DCDCE1'
                },
                '&:after' : {
                    borderBottom: '2px solid #009BC3'
                }
            },
        },
        MuiInputLabel:{
            root: {
                color: '#aaa',
            },
            filled: {
                display: 'none',
            }
        },
        MuiSelect: {
            filled: {
                '&&' : {
                    paddingTop : 7,
                    paddingLeft : 0,
                    paddingRight: 24,
                    background: 'none'
                }
            },
            icon: {
                top: 'calc(50% - 12px)'
            },
            iconFilled: {
                right: -5
            }
        },
        MuiIconButton: {
            colorPrimary: {
                color: '#009BC3',

                '&.Mui-checked': {
                    color: '#009BC3 !important'
                }
            }
        }
    },

});


export default customTheme