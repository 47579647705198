import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from "@material-ui/core/";
import { FC } from "react";

const useStyles = makeStyles(() => ({
      title:{
        "& h2": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        },
    }
  }));

const CustomDialog: FC<DialogProps> = ({ 
  openButton, 
  handleClickOpen,
  textButton,
  open,
  handleClose,
  classes,
  actions,
  dialogContent,
  dialogTitle,
  fullScreen
}) => {
  const internalClasses = useStyles();
  return (
    <div>
      {openButton !== undefined ? (
        openButton(handleClickOpen)
      ) : (
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {textButton}
        </Button>
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={async e => await handleClose(false)()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes?.root}
        fullScreen={fullScreen}
        disableEnforceFocus
      >
        <DialogTitle id="alert-dialog-title" className={`${classes?.dialogTitle} ${internalClasses.title}`}>
          <div>
            {dialogTitle}
          </div>
          <DialogActions className={classes?.dialogActions}>
            {actions}
          </DialogActions>
        </DialogTitle>
        <DialogContent className={classes?.dialogContent}>
          {dialogContent}
        </DialogContent>
      </Dialog>
    </div>
  );
};

interface DialogProps {
  openButton?: (handleClickOpen: () => void) => JSX.Element;
  handleClickOpen: () => void;
  textButton?: string;
  open: boolean;
  handleClose: (save: boolean) => () => void;
  classes?: {root?: string, dialogTitle?: string, dialogContent?: string, dialogActions?: string};
  actions?: JSX.Element;
  dialogContent: JSX.Element;
  dialogTitle: JSX.Element | string,
  fullScreen?: boolean
}

export default CustomDialog;
