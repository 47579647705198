import React, { useContext, useEffect, useState } from "react";
import ServicesContext, { Context } from "../../../services/ServicesContext";
import { format } from "sql-formatter";
import { observer } from "mobx-react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import Restore from "@material-ui/icons/Restore"
import CodeBlock from "../../common/CodeBlock";
import QueryService from "../../../services/queryService/queryService";
import { CustomDialog } from "../../common";
import { History } from "@material-ui/icons";
import ShowDialog from "../../common/ShowDialog";
import { getFormatedDateFromString } from "../../../helpers/timeHelper";

const useStyles = makeStyles((theme) => ({
  queryVersionHeader: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "space-between",
    alignItems: "center",
    "& button": {
      marginBottom: 5,
    }
  },
}));

const QueryString = () => {
  return <ShowDialog dialogTitle="History" openButtonName="History" openButtonIcon={<History />} content={<Content />} />;
};

const Content = observer(() => {
  const queryService = useContext<Context>(ServicesContext).queryService;
  const classes = useStyles();

  if (!queryService.currentQuery || !queryService.currentQueryVersion)
    return <div></div>;

  return (
    <div>
      {queryService.currentQuery.queryVersions.map((qv, i) => {
        if (queryService.currentQueryVersion?.id === qv.id)
          return null;
        

        const query = `~~~sql\n${format(qv.sqlQuery)}\n~~~`;
      
        return (
          <div key={i}>
            <div className={classes.queryVersionHeader}>
              {<Typography>{getFormatedDateFromString(qv.dateCreate.toString())}</Typography>}
              <Button 
                variant="outlined" 
                color={"primary"} 
                startIcon={<Restore />} 
                onClick={e => queryService.setNewCurrentVersion(qv.id as number)}>
                  Restore
              </Button>
            </div>
            <CodeBlock query={query} />
          </div>
        );
      })}
    </div>
  );
});

export default QueryString;
