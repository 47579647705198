import { Parameter } from "../components/query/dtoInterfaces";

const convertToParametersArray = (parameters: Array<Parameter>): Array<Array<Parameter>> => {
    parameters = parseToParametersArray(parameters);
    let tempArray = new Array<Array<Parameter>>();
    let finalArray = [];
    parameters.forEach(p => {
        if (tempArray.find(array => array.find(param => param.name === p.name))){
            tempArray.forEach(a => {
                if (a.find(param => param.name === p.name)){
                    a.push(p);
                }
            })
        }
        else {
            tempArray.push([p])
        }
    });
    let tempArrayAnyLength = tempArray[0]?.length;
    for (let i = 0; i < tempArrayAnyLength; i++){
        let temporaryArray = [];
        for (let j = 0; j < tempArray.length; j++){
            temporaryArray.push(tempArray[j][i]);
        }
        finalArray.push(temporaryArray);
    }
    return finalArray;
}

const parseToParametersArray = (parametersArray: Array<any>) => {
    return parametersArray.map(p => {
        let parameter: Parameter = {
            name: p.name,
            value: p.value,
            dataType: p.dataType,
            saved: p.saved,
            symbol: p.symbol,
            compareOperator: p.compareOperator,
        };
        return parameter;
    })
}

export default convertToParametersArray;