export const optionsDate: Intl.DateTimeFormatOptions = {
  year: "numeric", month: "numeric", day: "numeric",
  hour: "numeric", minute: "numeric",
  hour12: false
};

export const getFormatedDateFromString = (date: string): string => {
  return new Date(date).toLocaleString("ru-RU", optionsDate).toString().replace(",", "")
};

export const getFormatedDateFromDate = (date: Date): string => {
  return date.toLocaleString("ru-RU", optionsDate).toString().replace(",", "")
};