import {
  AppBar,
  Button,
  makeStyles,
  MenuItem,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Menu,
  MenuList,
  ListItemText,
  ListItemIcon,
  Box
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { Routes } from "../../configuration";
import api from "../../dataProvider/api";
import ServicesContext, { Context } from "../../services/ServicesContext";
import getDatabaseIcon from "../../helpers/getDatabaseIcon";

const useStyles = makeStyles((theme) => ({
  toolBar: {
    backgroundColor: theme.palette.primary.dark,
    justifyContent: "space-between",
  },
  toolBarLogin: {
    backgroundColor: theme.palette.primary.dark,
    justifyContent: "center",
  },
  content: {
    margin: "50px 50px",
  },
  layout: {
    height: "100%",
    width: "100%",
    display: "flex",
    zIndex: 1,
    position: "relative",
    minWidth: "auto",
    minHeight: "100vh",
    flexDirection: "column",
    backgroundColor: "white",
  },
  drawer: {
    width: 270,
  },
  dbIcon: {
    "filter": "grayscale(100%)",
    width: 20,
    height: 20,
  },
  chooseDatabaseLabel: {
    marginBottom: 10,
    marginTop: 20,
    fontWeight: "bold"
  },
  hasQueryLayout: {
    minWidth: 150,
    display: "flex",
  },
  queryListButton: {
    cursor: "pointer"
  },
  typographyMargin: {
    marginLeft: 16,
    marginRight: 16,
  }
}));

const HasQueryLayoutLable: React.FC<{queryName: string}> = (props) => {
  return (
      <Typography variant="h6">{props.queryName}</Typography>
  )
}

const Layout = (props: any) => {
  const accountService = useContext<Context>(ServicesContext).accountService;
  const queryService = useContext<Context>(ServicesContext).queryService;
  const currentDatabase = queryService.currentDatabase; 
  const databases = queryService.allDatabases;
  const [showDrawer, setShowDrawer] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  let tokenExist = accountService.isTokenExist();
  

  const handleChange = async (databaseType: number) => {
    if (!databases)
      return;

    queryService.trySetCurrentDatabase(databaseType as number);

    if (queryService.currentDatabase) {
      const queries = await api.getQueries(queryService.currentDatabase.id);
      queryService.queriesStore = queries;
      setShowDrawer(false);
    }
  }

  return (
    <div className={classes.layout}>
      <AppBar position="static">
        <Toolbar className={accountService.currentToken ? classes.toolBar : classes.toolBarLogin}>
          { !queryService?.currentQuery?.name && accountService.currentToken &&
            <IconButton
              onClick={() => setShowDrawer(!showDrawer)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          }
          {queryService?.currentQuery ? 
            <HasQueryLayoutLable queryName={queryService.currentQuery.name}/> :
            <Typography align="center" variant="h6">{ tokenExist && queryService.currentDatabase ? queryService.currentDatabase.name : "Active Query Builder ready-to-use query module"}</Typography>
          }
          <div>
          {tokenExist &&
            <Button
              color="inherit"
              onClick={(e) => {
                accountService.logout();
                history.push(Routes.signIn);
              }}
            >
              Logout
            </Button>
          }
          </div>
        </Toolbar>
        { !queryService?.currentQuery?.name && currentDatabase && 
          <Drawer
            anchor="left"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
          >
            <Box className={classes.drawer}>
              <Typography align="center" className={classes.chooseDatabaseLabel}>CHOOSE DATABASE</Typography>
              <MenuList>
                    {currentDatabase && tokenExist && databases && 
                      databases.map((d) => 
                        <MenuItem button onClick={() => handleChange(d.id)}>
                          <ListItemIcon><img src={getDatabaseIcon(d.databaseType)} className={classes.dbIcon}/></ListItemIcon>
                          <ListItemText primary={d.name} />
                        </MenuItem>)
                    }
              </MenuList>
            </Box>
          </Drawer>
        }
      </AppBar>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};


export default observer(Layout);